/*==== rtl css ====*/

.appie-traffic-area.appie-traffic-area-rtl .traffic-thumb {
  left: 0;
  right: auto;
  transform: translateY(-50%) rotateY(-180deg);
}

.appie-traffic-area-rtl .appie-traffic-title {
  padding-left: 220px;
  padding-right: 0;
}

.appie-traffic-area-rtl .appie-traffic-service .icon {
  right: 0;
  left: auto;
}

.appie-faq-area-rtl .faq-accrodion .accrodion .accrodion-title::after,
.appie-faq-area-rtl .faq-accrodion .accrodion .accrodion-title::before {
  right: auto;
  left: 30px;
}

.appie-project-area.appie-project-area-rtl
  .appie-project-box
  .appie-project-thumb {
  left: 70px;
  right: auto;
}

.appie-project-area-rtl .appie-project-content .input-box input {
  padding-left: 0;
  padding-right: 30px;
}

.appie-project-area-rtl .appie-project-content .input-box button {
  margin-left: 0;
  margin-right: 10px;
}

.appie-header-area-rtl .appie-header-main-menu ul {
  text-align: right;
}

.appie-header-area-rtl .appie-header-main-menu ul > li .sub-menu {
  text-align: right;
}
